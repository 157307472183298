.bannerImg4{
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
}
.storyText{
    width: 80%;
    margin: 0 auto;
}
.storyBox{
    background-color: var(--global-color-white);
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    box-shadow: 0px 5px 4px rgba(221, 221, 221, 0.25);
    border-radius: 5px;
    padding: 2rem;
    margin-bottom: 2rem;
    font-family: var(--global-font-family);
    color: #718096;
}

/* /////////////////////////// */
.education-crisis, .third-story-project, .how-to-help{ 
    padding: 2rem;
    border-radius: 0.5rem; 
  } 
  .help-option {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  
 .passIton_title{
    color: var(--global-color-header);
  }
  
  h5, h4 {
    color: var(--global-color-header);
    font-family: var(--global-font-family);
    font-weight: 700;
  }
  
  .cta {
    background-color: #e9ecef;
    padding: 3rem;
    border-radius: 0.5rem;
  }

  .together{
    background-color: #c59101;
  }
  /* //////////////////////////////// */
  .together-section {
    background-image: url('../../assets/images/pio2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
  }
  
  .together-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .together-content {
    position: relative;
    z-index: 1;
  }
  
  .together-title {
    margin-bottom: 1.5rem;
    color: #DAF9FF;
  }

  .together-title:after {
    border-bottom: 3px solid #DAF9FF;
}

  
  .together-text {
    padding: 1rem 0;
  }

  .passIton_title:after{
    content: "";
    position: absolute;
    left: 0%;
    bottom: 0;
    width: 100px;
    border-bottom: 3px solid #DAF9FF;
  }

  .pio-image {
    border: 5px solid #DAF9FF;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .pio-image:hover {
    transform: scale(1.02);
  }

  .bgItem2{
    position: absolute;
    right: 0;
    top: 0%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    z-index: -1;
}
.bgItem4{
    position: absolute;
    left: 0;
    bottom: 21px;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    
    .bgItem1,.bgItem2{
        width: 150px;
        height: auto;
    }

    .bgItem2 {
      display: none; /* This will hide bgItem2 */
  }
    
}


/* For smaller phones, you might want to add another breakpoint */
@media only screen and (max-width: 480px) {
  .bgItem1 {
      width: 100px; /* Further reduce size for smaller screens if needed */
      height: auto;
  }

  .bgItem2 {
      display: none; /* This ensures bgItem2 is also hidden on smaller phones */
  }
}